/*
 * @file en.js
 * @author liushengxgi
 * @date 2020-09-03 10:52:21
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2020-09-03 11:02:39
 */

export default {
    menuData: {
        dashboardTitle: 'Dashboard',
        editorialTitle: 'Editorial Tool',
        domainTitle: 'Domain Block',
        authorizeTitle: 'Authorization',
        roleTitle: 'Role List',
        accountTitle: 'Account List',
        userTitle: 'User List',
        managementTitle: 'Media List',
        audienceTitle: 'Audience targeting configuration',
        sensitiveTagTitle: 'Sensitive label list',
        sensitiveCfgTitle: 'Configure sensitive labels',
        adxTitle: 'ssp management',
        discoveryBWconfigTitle: 'D_SSP level 黑白名單',
        siteTitle: '站点管理',
        mediaTagTitle: '标签管理',
        mediaBWconfigTitle: 'Account-level media blacklist/whitelist',
        mediaBWconfig: 'Account-level media blacklist/whitelist',
        mediaSellerIdConfigTitle: 'Account-level Seller ID blacklist/whitelist',
        cruiseRejectBlackListTitle: 'Cruise Rejection',
        auditRejectOnceTitle: 'Single Rejection',
        creativeRiskTitle: 'Image Creative Risk Control',
        xandrAuditManagment: 'Brand Name Review Management',
        xandrAuditManagmentTitle: 'Brand Name Review Management'
    },
    button: {
        addAccount: 'Add account',
        addUser: 'Add user',
        addRole: 'Add role',
        status: 'status',
        del: 'Delete',
        bwList: 'Black and white list'
    },
    dashboard: {
        bwType: 'Blacklist & Whitelist Type',
        mediaSearchAcc: '根据 account 搜索用户',
        rpm: 'RPM',
        vrpm: 'vRPM',
        totalRevenue: 'Total Revenue',
        matchedRequest: 'Matched Ad Request',
        returnAds: 'Return Ads',
        viewsWithAds: 'Views with Ads',
        clicks: 'Ad Clicks',
        withAds: '% with Ads',
        withVisibility: '% with Visibility',
        vctr: 'vCTR',
        rendersWithAds: 'Renders With Ads',
        winRate: 'Win Rate',
        avgCPC: 'Avg CPC',
        accountName: 'Account Name',
        // Dimension
        dimensionDate: 'Date',
        dimensionAccount: 'Account',
        dimensionSSP: 'SSP',
        dimensionSite: 'Site',
        payin: 'payin'
    },
    editorial: {
        domain: {
            searchPlaceholder: 'Search ad domain / account name',
            remove: 'Remove from list',
            block: 'Add Blocking Domain'
        }
    },
    permission: {},
    management: {
        actionDetails: 'Action Details',
        xandrAsset: 'Asset',
        detailTip: 'Detail',
        operate: 'Operate',
        reAuditTip: 'Resubmit for Review',
        reAssetTip: 'Resubmit for Review',
        reAssetIcon: 'When entering LP and clicking search, all unarchived assets using this Lp can be displayed from the drop-down list in the resubmit asset below.',
        tokenBlockDetail: 'Seller ID Token Block Detail',
        sellerIdText: 'The brand name has been blocked by some seller ID token in the media, click the button on the right side to check the detail',
        pendingReview: 'Pending Review',
        underReview: 'Under Review',
        reject: 'Reject',
        pass: 'Pass',
        auditFailure: 'Audit failure',
        brandNameReviewManagement: 'Brand Name Review Management',
        tokenBlock: 'Token Block',
        notTokenBlock: 'Not token block',
        sellerReason: 'Reason',
        reAsset: 'Resubmit Asset',
        xanderBrandName: 'Brand Name',
        auditStatus: 'Audit Status',
        xanderReason: 'Reason',
        reviewTime: 'Review Time',
        submissionTime: 'Submission Time',
        reviewLang: 'Language',
        mediaLanguage: 'Media Language',
        type: 'Type',
        asset: 'Asset',
        lp: 'LP',
        searchLp: 'Enter LP to search Asset',
        addCreativeConfig: 'There are some creatives under this account that have the same dimension configuration, do they need to be overwritten and deleted?',
        creativeTip: 'When selecting account dimension control, you can choose summary control and decentralized  control; summary control means that the sum of accounts does not exceed the set upper limit; ecentralized control means that all account controls are applied to all creative id under it.',
        updateCreative: 'The {num} creatives under the account already have configurations of the same dimension, do you want to update?',
        deleteCreative: 'There are {num} creatives under this account that have been configured or are controlled by other accounts, do they need to be overwritten and deleted?',
        controlMethod: 'Control Method',
        sourceOfControl: 'Source of Control',
        summaryControl: 'summary control',
        decentralizedControl: 'decentralized control',
        itself: 'itself',
        creativePlaceholder: 'please use commas to separate',
        campaginSearch: 'Campaign ID',
        companyLevel: 'Company Level',
        accountLevel: 'Account Level',
        deleteDimension: 'After deletion, the condition restriction for this dimension will become invalid. Are you sure to delete?',
        companyName: 'Company Name',
        accountName: 'Account Name',
        accountId: 'Account ID',
        campaginId: 'Campaign ID',
        dimension: 'Dimension',
        audienceAdd: 'Add audience targeting',
        sensitiveTagAdd: 'Add sensitive labels',
        sensitiveCfgAdd: 'Add sensitive label configuration',
        // adx
        rejectedSources: 'Rejected Sources',
        modifyAdx: 'Modify adx',
        addWhiteList: 'Bulk operation whitelist',
        setWhiteForD: 'Discovery媒体白名单配置',
        setDisplaySsp: 'Display SSP自动上单配置',
        addAdx: 'Add ssp',
        adxSspId: 'sspid',
        adxSspName: 'ssp name',
        adxToken: 'token',
        adxHMedia: 'Whether it is advanced media',
        adxIp: 'Whether to use client ip',
        adxGEOEdge: 'GEO Edge',
        adxAddSuccess: 'Created successfully',
        adxModifySuccess: 'Modified successfully',
        timezone: 'timezopne',
        ssp: 'ssp',
        operateType: 'operateType',
        campaginName: 'campaginName',
        reason: 'Reason',
        creativeIdMsg: 'After deletion, no new rejection will be performed, and the assets that have been rejected will remain in the "Rejected" status.',
        rejectMsg: 'After termination, no new rejection will be performed, and the assets that have been rejected will remain in the "Rejected" status.',
        searchSpend: 'Search Spend',
        mediaRequire: 'media requirement',
        required: '{name} is required',
        dailyCost: 'Limit of Daily Spend',
        dailyImp: 'Limit of Daily Imp',
        assetImageMsg: 'After deletion, the conditional restrictions on the creative id will be invalid. Are you sure to delete the setting of this creative id?',
        dailyCostTip: 'The daily spend is the sum of the selected dimension consumption under the conditions set by the EST time zone. When the daily spend or imp any upper limit is reached, the corresponding traffic cannot be recalled.',
        dailyImpTip: ' The daily imp is the sum of the selected dimension consumption under the conditions set by the EST time zone. When the daily spend or imp any upper limit is reached, the corresponding traffic cannot be recalled.',
        countryTip: 'When multiple country are selected, they will be split for statistics, and the relationship between them and the selection in the dimension is N*N. For example, if you select the United States and the United Kingdom for the country, and account 1 is selected for the dimension, two pieces of data will appear on the page after submission.',
        hasCreativeId: 'The creative id already exists, please operate from the Edit entry in the action column',
        hasSppGroup: 'This SSP combination already exists, please edit it in the original combination',
        sitePlaceHolder: 'please use commas to separate',
        dailyCostValid: 'The daily cost must have no more than 2 decimal positions',
        element: 'Element',
        country: 'Country',
        language: 'Language',
        content: 'Content',
        rejectedReason: 'Rejection Reason',
        brandName: 'Brand name',
        keyword: 'Keyword',
        domainName: 'Domain',
        operator: 'Operator',
        reviewer: 'Reviewer',
        rejectAddSuccess: 'Add successfully',
        createTime: 'Create Time',
        completionTime: 'Completion Time',
        operatingTime: 'Operating Time',
        status: 'Status',
        doing: 'In execution, Click to terminate',
        stop: 'Terminated',
        done: 'Completed',
        download: 'Download',
        detail: 'detail',
        success: 'success',
        downloadToReject: 'Please download the details for a second confirmation of rejection',
        noDownload: 'Please download the details before confirming your rejection',
        isConfrimReject: 'Are you sure you want to reject them?',
        contentTitle: `【Example of Creative ID dimension content】
        URL：https://d2cli4kgl5uxre.cloudfront.net/ML/c5d69c5de0bbac6490bda9d103btestd.png
        Input：c5d69c5de0bbac6490bda9d103btestd
        （Between https://d2cli4kgl5uxre.cloudfront.net/ML/ and .png）
        【Example of Brand Name dimension content】
        Brand Name：Test Brand
        Input：Test Brand
        【Example of keyword dimension content】
        Headline：Be careful if you have these illegal words around you!
        「Case 1」Input：illegal words
        Match：Be careful if you have these illegal words around you!;;These illegal words will affect your life
        「Case 2」Input：Be careful if you have these illegal words around you!
        Match：Be careful if you have these illegal words around you!
        【Example of Domain dimension content】
        Landing Page：https://test.cloudfront.net/
        Input：test.cloudfront.net
        （Between https:// and /）
        `,
        rejectTitle: '* Operation records before April 17, 2023, are displayed as Baidu Rejected.'
    },
    rejectCountry: {
        CA:	'Canada',
        JP:	'Japan',
        US: 'USA',
        CH:	'Switzerland',
        PT:	'Portugal',
        BE:	'Belgium',
        MX:	'Mexico',
        BS:	'Brasil',
        HU:	'Hungary',
        DK:	'Denmark',
        NO:	'Norway',
        CZ:	'Czechia',
        SE:	'Sweden',
        PL:	'Poland',
        NL:	'Netherlands',
        ES:	'Spain',
        AT: 'Austria',
        IT:	'Italy',
        GB:	'United Kingdom',
        AU: 'Australia',
        KR: 'Korea',
        TW:	'Taiwan',
        ID:	'Indonesia',
        SG:	'Singapore',
        MY: 'Malaysia',
        TH:	'Thailand',
        HK:	'Hong Kong',
        DE:	'Germany',
        FR:	'France'
    },
    rejectLanguage: {
        en: 'English',
        de: 'German',
        pt: 'Portuguese',
        fr: 'French',
        es: 'Spanish',
        hu: 'Hungarian',
        da: 'Danish',
        nb: 'Norwegian',
        cs: 'Czech',
        sv: 'Swedish',
        pl: 'Polish',
        nl: 'Dutch',
        it: 'Italian'
    },
    rejectReason: {
        celebrity: 'Rejected due to non-compliant celebrity headline',
        Cryptocurrency: 'Rejected due to non-compliant Cryptocurrency headline',
        suffering: 'Rejected due to non-compliant suffering/violence headline',
        sensitive: 'Rejected due to non-compliant sensitive headline'
    }
};
