var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data && _vm.data['key'] && !_vm.data['hideInMenu'])?_c('div',{staticClass:"msn-menu"},[(_vm.data['children'])?_c('el-submenu',{key:_vm.data['key'],attrs:{"index":_vm.getKey(_vm.routekey)}},[_c('template',{slot:"title"},[(_vm.data['cornerIcon'])?_c('div',{class:['msn-menu-corner-icon', `msn-menu-corner-icon-${_vm.data['cornerIcon']}`]}):_vm._e(),_vm._v(" "),(_vm.data['icon'])?_c('i',{class:[
                    'scaleIcon-transition',
                    _vm.getIconClass(_vm.data['icon']),
                    {scaleIcon: _vm.asideStatus},
                ]}):_vm._e(),_vm._v(" "),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("\n                "+_vm._s(_vm.$i18n.t(_vm.data["title"]))+"\n                "),(_vm.data['bageKey'] && (_vm.accountInfo && +_vm.accountInfo[_vm.data['bageKey']] > 0))?_c('span',{staticClass:"menu-bage"},[_vm._v(_vm._s(_vm.accountInfo[_vm.data["bageKey"]]))]):_vm._e()])]),_vm._v(" "),_c('el-menu-item-group',[_vm._l((_vm.data['children']),function(child){return [(child && child['key'])?_c('menu-item',{key:child['key'],attrs:{"routekey":_vm.getKey(_vm.routekey, child['key']),"data":child}}):_vm._e()]})],2)],2):_c('el-menu-item',{key:_vm.data['key'],attrs:{"index":_vm.getKey(_vm.routekey)}},[(_vm.data['cornerIcon'])?_c('div',{class:['msn-menu-corner-icon', `msn-menu-corner-icon-${_vm.data['cornerIcon']}`]}):_vm._e(),_vm._v(" "),(_vm.data['icon'])?_c('i',{class:[
                'scaleIcon-transition',
                _vm.getIconClass(_vm.data['icon']),
                {scaleIcon: _vm.asideStatus},
            ]}):_vm._e(),_vm._v(" "),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("\n            "+_vm._s(_vm.$i18n.t(_vm.data["title"]))+"\n            "),(_vm.data['bageKey'] && (_vm.accountInfo && +_vm.accountInfo[_vm.data['bageKey']] > 0))?_c('span',{staticClass:"menu-bage"},[_vm._v(_vm._s(_vm.accountInfo[_vm.data["bageKey"]]))]):_vm._e()])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }