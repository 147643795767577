/**
 * @file msn模块的路径，组件，菜单，权限相关配置
 * @author  fangsimin
 * @date    2020-02-09 17:49:50
 * @last Modified by    fangsimin
 * @last Modified time  2020-03-23 17:08:22
 */

import {MODULE_PLATFORM} from '@/modules/media/constants';
const env = process.env;
const module = MODULE_PLATFORM;

// 路径，组件菜单配置
// 路径：必须包含key
// 组件：必须包含title
const config = [
    {
        icon: 'dashboard',
        key: 'dashboard',
        title: 'media.menuData.dashboardTitle',
        pTitle: 'media.menuData.dashboardTitle',
        actions: [
            {
                pTitle: 'media.dashboard.mediaSearchAcc',
                key: 'mediaSearchAcc'
            }
        ],
        component: resolve => require(['@/modules/media/pages/dashboard/dashboard'], resolve)
    },
    {
        icon: 'am',
        key: 'editorial',
        title: 'media.menuData.editorialTitle',
        pTitle: 'media.menuData.editorialTitle',
        children: [
            {
                icon: 'menuData',
                key: 'domain',
                title: 'media.menuData.domainTitle',
                pTitle: 'media.menuData.domainTitle',
                actions: [
                    {
                        pTitle: 'media.button.del',
                        key: 'del'
                    },
                    {
                        pTitle: 'media.editorial.domain.block',
                        key: 'block'
                    }
                ],
                component: resolve => require(['@/modules/media/pages/editorial/domain/domain'], resolve)
            }
        ]
    },
    {
        icon: 'author',
        key: 'authorize',
        title: 'media.menuData.authorizeTitle',
        pTitle: 'media.menuData.authorizeTitle',
        children: [
            {
                icon: 'account',
                key: 'account',
                title: 'media.menuData.accountTitle',
                pTitle: 'media.menuData.accountTitle',
                actions: [
                    {
                        pTitle: 'msn.button.edit',
                        key: 'edit'
                    },
                    {
                        pTitle: 'media.button.status',
                        key: 'status'
                    },
                    {
                        pTitle: 'media.button.addAccount',
                        key: 'add'
                    }
                ],
                component: resolve => require(['@/modules/media/pages/authorization/account/accountMgr'], resolve)
            },
            {
                icon: 'users',
                key: 'user',
                title: 'media.menuData.userTitle',
                pTitle: 'media.menuData.userTitle',
                actions: [
                    {
                        pTitle: 'msn.button.edit',
                        key: 'edit'
                    },
                    {
                        pTitle: 'media.button.status',
                        key: 'status'
                    },
                    {
                        pTitle: 'media.button.addUser',
                        key: 'add'
                    }
                ],
                component: resolve => require(['@/modules/media/pages/authorization/user/userMgr'], resolve)
            },
            {
                icon: 'role',
                key: 'role',
                title: 'media.menuData.roleTitle',
                pTitle: 'media.menuData.roleTitle',
                actions: [
                    {
                        pTitle: 'msn.button.edit',
                        key: 'edit'
                    },
                    {
                        pTitle: 'media.button.addRole',
                        key: 'add'
                    }
                ],
                component: resolve => require(['@/modules/media/pages/authorization/role/roleMgr'], resolve)
            }
        ]
    },
    {
        icon: 'mediaicon',
        key: 'management',
        title: 'media.menuData.managementTitle',
        pTitle: 'media.menuData.managementTitle',
        children: [
            {
                icon: 'sensitiveTag',
                key: 'sensitiveTag',
                title: 'media.menuData.sensitiveTagTitle',
                pTitle: 'media.menuData.sensitiveTagTitle',
                actions: [
                    {
                        pTitle: 'media.management.sensitiveTagAdd',
                        key: 'add'
                    },
                    {
                        pTitle: 'msn.button.edit',
                        key: 'edit'
                    }
                ],
                component: resolve => require(['@/modules/media/pages/mediaMgr/sensitive/senMgr'], resolve)
            },
            {
                icon: 'sensitiveCfg',
                key: 'sensitiveCfg',
                title: 'media.menuData.sensitiveCfgTitle',
                pTitle: 'media.menuData.sensitiveCfgTitle',
                actions: [
                    {
                        pTitle: 'media.management.sensitiveCfgAdd',
                        key: 'add'
                    },
                    {
                        pTitle: 'msn.button.edit',
                        key: 'edit'
                    }
                ],
                component: resolve => require(['@/modules/media/pages/mediaMgr/sensitiveCfg/list'], resolve)
            },
            {
                icon: 'audience',
                key: 'audience',
                title: 'media.menuData.audienceTitle',
                pTitle: 'media.menuData.audienceTitle',
                actions: [
                    {
                        pTitle: 'media.management.audienceAdd',
                        key: 'add'
                    },
                    {
                        pTitle: 'msn.button.edit',
                        key: 'edit'
                    }
                ],
                component: resolve => require(['@/modules/media/pages/mediaMgr/audience/list'], resolve)
            },
            {
                icon: 'adx',
                key: 'adx',
                title: 'media.menuData.adxTitle',
                pTitle: 'media.menuData.adxTitle',
                actions: [
                    {
                        pTitle: 'media.management.addAdx',
                        key: 'addAdx'
                    },
                    {
                        pTitle: 'media.management.modifyAdx',
                        key: 'modifyAdx'
                    },
                    {
                        pTitle: 'media.management.addWhiteList',
                        key: 'addWhiteList'
                    },
                    {
                        pTitle: 'media.management.setWhiteForD',
                        key: 'setWhiteForD'
                    },
                    {
                        pTitle: 'media.management.setDisplaySsp',
                        key: 'setDisplaySsp'
                    }
                ],
                component: resolve => require(['@/modules/media/pages/mediaMgr/adx/list'], resolve)
            },
            {
                icon: 'discoveryBWconfig',
                key: 'discoveryBWconfig',
                title: 'media.menuData.discoveryBWconfigTitle',
                pTitle: 'media.menuData.discoveryBWconfigTitle',
                component: resolve => require(['@/modules/media/pages/mediaMgr/dSspBWconfig/index'], resolve)
            },
            {
                icon: 'site',
                key: 'site',
                title: 'media.menuData.siteTitle',
                pTitle: 'media.menuData.siteTitle',
                // actions: [
                //     {
                //         pTitle: 'media.management.addAdx',
                //         key: 'addAdx'
                //     }
                // ],
                component: resolve => require(['@/modules/media/pages/mediaMgr/site/index'], resolve)
            },
            {
                icon: 'mediaTag',
                key: 'mediaTag',
                title: 'media.menuData.mediaTagTitle',
                pTitle: 'media.menuData.mediaTagTitle',
                // actions: [
                //     {
                //         pTitle: 'media.management.addAdx',
                //         key: 'addAdx'
                //     }
                // ],
                component: resolve => require(['@/modules/media/pages/mediaMgr/mediaTag/index'], resolve)
            },
             {
                icon: 'mediaBWconfig',
                key: 'mediaBWconfig',
                title: 'media.menuData.mediaBWconfig',
                pTitle: 'media.menuData.mediaBWconfig',
                component: resolve => require(['@/modules/media/pages/mediaMgr/bwConfig/index'], resolve)
            },
            {
                icon: 'mediaSellerIdConfig',
                key: 'mediaSellerIdConfig', // 提测需要修改回来
                title: 'media.menuData.mediaSellerIdConfigTitle',
                pTitle: 'media.menuData.mediaSellerIdConfigTitle',
                component: resolve => require(['@/modules/media/pages/mediaMgr/sellerIdConfig/index'], resolve)
            },
            {
                icon: 'cruiseRejectBlackList',
                key: 'cruiseRejectBlackList',
                title: 'media.menuData.cruiseRejectBlackListTitle',
                pTitle: 'media.menuData.cruiseRejectBlackListTitle',
                component: resolve => require(['@/modules/media/pages/mediaMgr/creativeIdTag/index'], resolve)
            },
            {
                icon: 'auditRejectOnce',
                key: 'auditRejectOnce',
                title: 'media.menuData.auditRejectOnceTitle',
                pTitle: 'media.menuData.auditRejectOnceTitle',
                component: resolve => require(['@/modules/media/pages/mediaMgr/singleRejected/index'], resolve)
            },
            {
                icon: 'mediaTag',
                key: 'creativeRisk',
                title: 'media.menuData.creativeRiskTitle',
                pTitle: 'media.menuData.creativeRiskTitle',
                component: resolve => require(['@/modules/media/pages/mediaMgr/creativeRiskCtr/index'], resolve)
            },
            {
                icon: 'xandrAuditManagment',
                key: 'xandrAuditManagment',
                title: 'media.menuData.xandrAuditManagment',
                pTitle: 'media.menuData.xandrAuditManagment',
                component: resolve => require(['@/modules/media/pages/mediaMgr/xandrAuditManagment/index'], resolve)
            }
        ]
    }
];

/**
 * 获取真正的路径
 * @param  {string} path 路径
 * @param  {boolean} hasModule 是否包含模块
 * @return {string}
 */
function getModulePath(path, hasModule = true) {
    return path
        ? (hasModule ? [env['VUE_APP_BASE_URI'], module, path]
            : [env['VUE_APP_BASE_URI'], path]).join('/')
        : '/';
}

/**
 * 通过前面的config配置，获取menu和路径配置
 *
 * @param  {Object} config [description]
 * @return {Object}        [description]
 */
function getConfig(config) {
    let routerConfig = [];
    let path = {};
    // 需要将数据深克隆，否则后续的更改会修改数据
    const rawConfig = JSON.parse(JSON.stringify(config));
    let author = rawConfig.reduce((ret, item) => {
        if (item['key']) {
            let curPath = getModulePath(item['key']);
            if (item['pTitle']) {
                if (item['children']) {
                    item['children'] = item['children'].reduce((ret, child) => {
                        if (child['key']) {
                            let keys = [item['key'], child['key']];
                            let curPath = getModulePath(keys.join('/'));
                            if (child['pTitle']) {
                                child['path'] = curPath;
                                if (child['actions']) {
                                    child['children'] = child['actions'].map(action => {
                                        return {
                                            path: `${curPath}:${action.key}`,
                                            ...action
                                        };
                                    });
                                }
                                ret.push(child);
                            }
                        }
                        return ret;
                    }, []);
                }
                item['path'] = curPath;
                if (item['actions']) {
                    item['children'] = item['actions'].map(action => {
                        return {
                            path: `${curPath}:${action.key}`,
                            ...action
                        };
                    });
                }
                ret.push(item);
            }
        }
        return ret;
    }, []);
    let menu = config.reduce((ret, item) => {
        if (item['key']) {
            let curPath = getModulePath(item['key']);
            if (item['title']) {
                // item['title'] = this.$i18n.t(item['title']);
                if (item['children']) {
                    item['children'] = item['children'].reduce((ret, child) => {
                        if (child['key']) {
                            let keys = [item['key'], child['key']];
                            let curPath = getModulePath(keys.join('/'));
                            if (child['title']) {
                                // child['title'] = this.$i18n.t(child['title']);
                                child['path'] = curPath;
                                ret.push(child);
                            }
                            path[keys.join('_')] = curPath;
                            if (child['component']) {
                                // let componentPath = ['@/modules', module, 'pages', ...keys].join('/');
                                // console.log(componentPath);
                                routerConfig.push({
                                    path: path[keys.join('_')],
                                    component: child['component']
                                });
                            }
                        }
                        return ret;
                    }, []);
                }
                item['path'] = curPath;
                ret.push(item);
            }
            path[item['key']] = curPath;
            if (item['component']) {
                routerConfig.push({
                    path: path[item['key']],
                    component: item['component']
                });
            }
        }
        return ret;
    }, []);
    return {
        menu,
        path,
        author,
        routes: routerConfig
    };
}

export default getConfig(config);
