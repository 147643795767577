/**
 * @file 可编辑表格
 * @author  fangsimin
 * @date    2019-01-18 14:05:39
 * @last Modified by    fangsimin
 * @last Modified time  2019-01-18 19:21:42
 */

<template>
    <div>
        <el-table
            :data="data" border
            style="width: 100%"
        >
            <template v-for="(col, i) in cols">
                <template v-if="col['prop']">
                    <el-table-column
                        :key="i"
                        :prop="col['prop']"
                        :label="col['label'] || ''"
                    >
                        <template slot-scope="scope">
                            <template v-if="rowCache[scope.$index] && rowCache[scope.$index]['isEditing'] && col['edit']">
                                <edit-item
                                    v-model="rowCache[scope.$index][col['prop']]"
                                    type="col['edit']['type'] || 'input'"
                                />
                            </template>
                            <slot
                                v-else-if="col['slotName']"
                                :name="col['slotName']"
                                :item="getItemData(scope, col)"
                                :row="scope.row"
                                :column="scope.column"
                                :store="scope.store"
                                :_self="scope._self"
                                :$index="scope.$index"
                            >
                            </slot>
                            <template v-else>
                                {{ getItemData(scope, col) }}
                            </template>
                        </template>
                    </el-table-column>
                </template>
            </template>
            <el-table-column align="right">
                <template slot="header">
                    <el-button
                        size="mini" type="primary"
                        @click="handleAdd()"
                    >
                        {{ $t('message.button.add') }}
                    </el-button>
                </template>
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">
                        {{ $t('message.button.edit') }}
                    </el-button>
                    <!-- <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">
                    {{$t('message.button.delete')}}</el-button> -->
                    <confirm-delete-button :confirm="getHandleDelete(scope.$index, scope.row)"/>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import EditItem from './EditItem';
// import constants from '@/constants';
import ConfirmDeleteButton from '@/components/ConfirmDeleteButton';

export default {
    props: {
        data: { // 表格数据
            type: Array,
            default: () => []
        },
        cols: { // 表格列定义
            type: Array,
            default: () => []
        }
    },
    data: function () {
        return {
            loading: false,
            rowCache: {} // 缓存一些行的信息或状态，eg: 是否处于编辑状态
        };
    },
    components: {
        ConfirmDeleteButton,
        EditItem
    },
    methods: {
        get(data) {
            console.log(data);
        },
        handleAdd() {
        },
        handleEdit(index, row) {
            let rowCache = JSON.parse(JSON.stringify(this.rowCache));
            if (!rowCache[index]) {
                rowCache[index] = {};
            }
            rowCache[index]['isEditing'] = true;
            this.rowCache = rowCache;
            console.log('handleEdit', index, row, this.rowCache[index]);
        },

        /**
         * 获取删除回调
         * @param  {number} index 行序号
         * @param  {Object} row   行数据
         * @return {function}
         */
        getHandleDelete(index, row) {
            return () => {

            };
        },

        /**
         * 获取某个格子内的数据
         * @param  {Object} scope 格子相应行，列等数据
         * @param  {Object} col 列配置数据
         * @return {string}
         */
        getItemData(scope, col) {
            if (col && col['prop']
                && scope && scope.row) {
                let data = scope.row[col['prop']] || '-';
                return data;
            }
            return '-';
        }
    },
    mounted() {

    }
};
</script>
