/**
 * @file 菜单
 * @author  fangsimin
 * @date    2019-01-02 10:55:08
 * @last Modified by    fangsimin
 * @last Modified time  2020-02-09 21:11:36
 */
<template>
    <el-menu
        v-if="data && data.length"
        :default-active="activeIndex"
        :mode="mode"
        :collapse="isCollapse"
        :collapse-transition="false"
        :router="true"
        class="msn-menu" @select="handleSelect"
    >
        <template v-for="item in data">
            <menu-item
                v-if="item && item['key']"
                :key="item['key']"
                :routekey="'/' + (base ? base + '/' : '') + item['key']"
                :data="item"
                :aside-status="isCollapse"
            />
        </template>
    </el-menu>
</template>

<script>

import MenuItem from './MenuItem';
import constants from '@/constants';
const PATHS = constants.path;

export default {
    props: {
        mode: {
            default: 'vertical',
            type: String
        }, // 模式：horizontal / vertical
        data: {
            type: Array,
            default: () => []
        },
        base: {
            default: ''
        },
        isCollapse: {// 是否是合起状态，合起模式只显示icon
            type: Boolean,
            default: true
        }
    },
    data() {
        return {};
    },
    computed: {
        activeIndex() {
            let active = (this.$route && this.$route.path) || '';
            // 从pixel进入新增页面，需要菜单激活pixel菜单
            if (active.includes('pixel')) {
                if (active.includes('pixel/conversions')) {
                    active = PATHS['pixelConversions'];
                } else if (active.includes('pixel/readList') || active.includes('pixel/discoveryReadCreate')) {
                    active = PATHS['readList'];
                } else {
                    active = PATHS['pixelList'];
                }
            }
            if (active.includes('payAction')) {
                active = PATHS['recharge'];
            }
            if (active.includes('bdOpenAccount')) {
                active = PATHS['accountList'];
            }
            return active;
        }
    },
    methods: {
        handleSelect(key, keyPath) {
            // console.log(key, keyPath);
        }
    },
    components: {
        MenuItem
    }
};
</script>
<style lang="scss">
.el-submenu__title {
    height: 70px;
    line-height: 70px;
}
.el-menu-item:hover, .el-menu-item:focus {
    background-color: transparent;
}
</style>
