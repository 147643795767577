/**
 * @file 注入请求，错误会直接显示服务端返回的错误
 * @author  fangsimin
 * @date    2019-01-14 19:22:18
 * @last Modified by    fangsimin
 * @last Modified time  2019-01-14 20:54:44
 */

import Vue from 'vue';
import request from '@/common/request';
import PATH from '@/constants/path';

let AjaxPlugin = {};

/**
 * 请求插件安装
 * @param  {Object} Vue     vue类
 * @param  {Object} options 配置
 */
AjaxPlugin.install = function (Vue) {
    /**
     * 添加实例方法，请求
     * @param  {Object} reqOptions [description]
     * @param  {Object} data       [description]
     */
    Vue.prototype.$request = function (reqOptions, data) {
        let successCb = reqOptions['success']; // 成功回调
        let errorCb = reqOptions['error']; // 失败回调
        let loadingKey = reqOptions['loadingKey'] || 'loading';
        // 调用下拉框数据接口时不设置loading
        if (!reqOptions['selectOption']) {
            this[loadingKey] = true;
        }

        // 请求
        request(reqOptions, data).then(response => {
            typeof successCb === 'function' && successCb.call(this, response);
        }, response => {
            if (response && (response.status === 200 || response.status === 304)) {
                if (response.data && response.data.msg) {
                    const msg = response.data.msg;
                    if (reqOptions.isAgentList) return;
                    if (msg.indexOf('.') > -1) {
                        this.$message.error(this.$i18n.t(msg));
                    } else {
                        this.$message.error(msg);
                    }
                } else {
                    this.$message.error(this.$i18n.t('msn.requestMsg.requestError'));
                }
            } else if (response && response.status === 401) {
                // 处理版本更新问题
                this.$confirm(this.$t('msn.infos.sessionInvalid'), this.$t('msn.infos.info'), {
                    customClass: 'msn-message-box',
                    showClose: false,
                    cancelButtonText: this.$t('msn.auditEn.cancel'),
                    confirmButtonText: this.$t('msn.auditEn.confirm'),
                    cancelButtonClass: 'msn-cancel-button',
                    confirmButtonClass: 'msn-confirm-button'
                }).then(_ => {
                    location.href = PATH['login'];
                }).catch(_ => {
                    location.href = PATH['login'];
                });
            } else {
                // 特别处理aiCreative模块的错误提示
                if(response.data && response.data.msg === 'msn.aiCreativeGo.requestErrorTip'){
                    this.$message.error(this.$i18n.t('msn.aiCreativeGo.requestErrorTip'));
                }else{
                    const messagae = this.$i18n.t('msn.requestMsg.requestError');
                    this.$message.error(messagae);
                }
            }
            typeof errorCb === 'function' && errorCb.call(this, response);
        }).finally(() => {
            this[loadingKey] = false;
        });
    };
};

Vue.use(AjaxPlugin);
