
import store from '@/store';
export default class GaReport {
    static instance = null
    constructor(version) {
        this.version = version || 'v1';
    }
    init() {
        if(!GaReport.instance) {
            GaReport.instance = this;
        }
        return GaReport.instance;
    }
    reportUserInfo() {
        console.log(store.state);
        const {email, role: roles, accountList }= store.state.msn;
        const {platformInfo} = store.state.common;
        const option = {
            email,
            roles,
            accounts_count: accountList.length,
            brand: platformInfo.platform
        };
        window.gtag && window.gtag('event', 'userInfo', option);
    }
    report(params, rest = {}) {
        let {action, category, label} = params;
        const defaultCategory =  window.location.pathname.replace('/platform/', '');
        // m每次上报必传信息
        const {lang, currency, timezone} = store.state.common;
        console.log(currency);
        const event_category = `${this.version}_${category || defaultCategory}`;
        const option = {
            event_category,
            event_label: label,
            lang,
            currency,
            timezone,
            ...rest
        };
        window.gtag && window.gtag('event', action, option);
    }
}

