/**
 * @file 编辑表格使用的常量
 * @author  fangsimin
 * @date    2019-01-18 16:05:22
 * @last Modified by    fangsimin
 * @last Modified time  2019-01-18 16:07:58
 */

export default {
    editTypes: {
        text: 'text', // 不可编辑，依旧为文本
        input: 'input', // 文本输入
        inputNumber: 'inputNumber', // 数字输入
        select: 'select' // 单选
    }
};
