
export const currencyList = [
    {
        label: 'msn.currencySpecies.dollar',
        value: 'USD'
    },
    {
        label: 'msn.currencySpecies.bahtDollar',
        value: 'THB'
    },
    {
        label: 'msn.currencySpecies.japaneseDollar',
        value: 'JPY'
    },
    {
        label: 'msn.currencySpecies.taiwanDollar',
        value: 'TWD'
    },
    {
        label: 'msn.currencySpecies.HKDollar',
        value: 'HKD'
    },
    {
        label: 'msn.currencySpecies.singaporeDollar',
        value: 'SGD'
    },
    {
        label: 'msn.currencySpecies.wonDollar',
        value: 'KRW'
    },
    {
        label: 'msn.currencySpecies.malaysianRupiah',
        value: 'MYR'
    },
    {
        label: 'msn.currencySpecies.indonesianRupiah',
        value: 'IDR'
    },
    {
        label: 'msn.currencySpecies.RMB',
        value: 'CNY'
    }
];
export const minLimitList = [
    {
        currency: 'USD',
        value: 200
    },
    {
        currency: 'KRW',
        value: 250000
    },
    {
        currency: 'THB',
        value: 7000
    },
    {
        currency: 'JPY',
        value: 23000
    },
    {
        currency: 'TWD',
        value: 5600
    },
    {
        currency: 'HKD',
        value: 1600
    },
    {
        currency: 'IDR',
        value: 2900000
    },
    {
        currency: 'MYR',
        value: 850
    },
    {
        currency: 'SGD',
        value: 270
    },
    {
        currency: 'CNY',
        value: 1300
    }
];