var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"checkbox-group-container"},[_c('div',{staticClass:"checkbox-header",class:{'checkbox-header1': (_vm.titleSuffix !== '')}},[_c('div',{staticClass:"checkbox-title"},[_vm._v("\n            "+_vm._s(_vm.title)+"\n            "),(_vm.titleSuffix !== '')?_c('div',{staticClass:"checkbox-header1-suffix"},[_vm._v("\n                "+_vm._s(_vm.titleSuffix)+"\n            ")]):_vm._e()]),_vm._v(" "),(!_vm.noSelectAll)?_c('div',{staticClass:"checkbox-all"},[_c('el-checkbox',{attrs:{"indeterminate":_vm.allSelect.indeterminate},on:{"change":_vm.checkAll},model:{value:(_vm.allSelect.selected),callback:function ($$v) {_vm.$set(_vm.allSelect, "selected", $$v)},expression:"allSelect.selected"}},[_vm._v("\n                "+_vm._s(_vm.$t('msn.infos.allSelect'))+"\n            ")])],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"checkbox-wrapper"},[_c('el-checkbox-group',{attrs:{"min":_vm.min,"max":_vm.max},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[(_vm.type !== 'dashboardAd')?_vm._l((_vm.checkOptions),function(item){return _c('el-checkbox',{key:item.label,staticClass:"checkbox-container",class:{'checkbox-item-hidden': _vm.type === 'dashboardDomain' && item.value === 'cpc' && !_vm.showCPC},style:(( item.value === 'mcv' && _vm.adListColCvStyle)
                        || ( item.value === 'cv' && _vm.adListColCvStyle)
                        || ( item.value === 'cvr' && _vm.adListColCvStyle)
                        || ( item.value === 'mcvr' && _vm.adListColCvStyle)),attrs:{"label":item.value,"disabled":_vm.disableData.includes(item.value)},on:{"change":function($event){return _vm.handleChange($event, item)}}},[_vm._v("\n                    "+_vm._s(item.noUseTranslate ? item.label : _vm.$t(item.label))+"\n                ")])}):_vm._l((_vm.checkOptions),function(item){return _c('el-checkbox',{key:item.label,staticClass:"checkbox-container",style:(_vm.getItemStyle(item.value) || (item.value === 'size' && _vm.adListColSizeStyle)
                        || ( item.value === 'mcv' && _vm.adListColCvStyle)
                        || ( item.value === 'cv' && _vm.adListColCvStyle)
                        || ( item.value === 'cvr' && _vm.adListColCvStyle)
                        || ( item.value === 'mcvr' && _vm.adListColCvStyle)),attrs:{"label":item.value,"disabled":_vm.disableData.includes(item.value) || (item.value === 'size' && !_vm.canAdListColSize)},on:{"change":function($event){return _vm.handleChange($event, item)}}},[_vm._v("\n                    "+_vm._s(item.noUseTranslate ? item.label : _vm.$t(item.label))+"\n                ")])})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }