/**
 * @file msn模块的路径，组件，菜单，权限相关配置
 * @author  fangsimin
 * @date    2020-02-09 17:49:50
 * @last Modified by    fangsimin@baidu.com
 * @last Modified time  2021-06-25 15:31:45
 */

import {MODULE_PLATFORM} from '@/modules/msn/constants';
import {getDiscoveryExcludeRoutes} from './discoveryExcludeRoutes';
const env = process.env;
const module = MODULE_PLATFORM;

// 路径，组件菜单配置
// 路径：必须包含key
// 组件：必须包含title
const config = [
    {
        icon: 'dashboard',
        key: 'new_dashboard',
        title: 'msn.menuData.new_dashboardTitle',
        pTitle: 'msn.menuData.new_dashboardTitle',
        actions: [
            {
                pTitle: 'msn.dashboard.newColAgency',
                key: 'agencyList'
            },
            {
                pTitle: 'msn.dashboard.status',
                key: 'status'
            },
            {
                pTitle: 'msn.dashboard.review',
                key: 'review'
            },
            {
                pTitle: 'msn.dashboard.campaignDetails',
                key: 'detail'
            },
            {
                pTitle: 'msn.dashboard.campaignEdit',
                key: 'edit'
            },
            {
                pTitle: 'msn.dashboard.campaignCopy',
                key: 'copy'
            },
            {
                pTitle: 'msn.dashboard.adStatus',
                key: 'adStatus'
            },
            {
                pTitle: 'msn.dashboard.dashboardDomainBlock',
                key: 'domainBlock'
            },
            {
                pTitle: 'msn.dashboard.domainSspColumn',
                key: 'domainSspColumn'
            },
            {
                pTitle: 'msn.dashboard.auditPublisher',
                key: 'auditPublisher'
            },
            {
                pTitle: 'msn.dashboard.adListColSize',
                key: 'adListColSize'
            },
            {
                pTitle: 'msn.dashboard.dimensionFilter',
                key: 'dimensionFilter'
            },
            {
                pTitle: 'msn.dashboard.newConversiontype',
                key: 'conversiontype'
            },
            {
                pTitle: 'msn.dashboard.mediagoCv',
                key: 'mediagoCv'
            },
            {
                pTitle: 'msn.dashboard.campaignSetOcpc',
                key: 'campaignSetOcpc'
            },
            {
                pTitle: 'msn.dashboard.campaignSetEcpc',
                key: 'campaignSetEcpc'
            },
            {
                pTitle: 'msn.supplement.domainSetCampaignCpc',
                key: 'domainSetCampaignCpc'
            },
            {
                pTitle: 'msn.dashboard.domainTitle',
                key: 'domainTab'
            },
            {
                pTitle: 'msn.dashboard.readChartTitle',
                key: 'read'
            },
            {
                pTitle: 'msn.dashboard.bidByDomain',
                key: 'bidByDomainCPC'
            },
            {
                pTitle: 'msn.dashboard.suggested',
                key: 'suggestedCpc'
            },
            {
                pTitle: 'msn.dashboard.country',
                key: 'country'
            },
            {
                pTitle: 'msn.dashboard.vcpm',
                key: 'vCPM'
            },
            {
                pTitle: 'msn.roiMgr.source',
                key: 'agency'
            }

        ],
        component: resolve => require(['@/modules/msn/pages/new_dashboard/dashboard'], resolve)
    },
    {
        icon: 'am',
        key: 'am',
        title: 'msn.menuData.amTitle',
        pTitle: 'msn.menuData.amTitle',
        children: [
            {
                icon: 'menuData',
                key: 'amConfig',
                title: 'msn.menuData.amConfig',
                pTitle: 'msn.menuData.amConfig',
                actions: [
                    {
                        title: 'msn.menuData.amConfigCountr',
                        key: 'amConfigCountr'
                    }
                ],
                component: resolve => require(['@/modules/msn/pages/am/amConfig/index.vue'], resolve)
            }
    //         {
    //             icon: 'icafe',
    //             key: 'icafe',
    //             title: 'msn.menuData.icafeTitle',
    //             pTitle: 'msn.menuData.icafeTitle',
    //             actions: [
    //                 {
    //                     pTitle: 'msn.amDashboard.icafe.newItem',
    //                     key: 'create'
    //                 },
    //                 {
    //                     pTitle: 'msn.button.edit',
    //                     key: 'edit'
    //                 }
    //             ],
    //             component: resolve => require(['@/modules/msn/pages/am/icafe/icafe.vue'], resolve)
    //         },
    //         {
    //             icon: 'sample',
    //             key: 'sample',
    //             title: 'msn.menuData.sampleTitle',
    //             pTitle: 'msn.menuData.sampleTitle',
    //             component: resolve => require(['@/modules/msn/pages/am/sample/index'], resolve)
    //         },
    //         {
    //             icon: 'qaCenter',
    //             key: 'qaCenter',
    //             title: 'msn.menuData.qaCenterTitle',
    //             pTitle: 'msn.menuData.qaCenterTitle',
    //             actions: [
    //                 {
    //                     pTitle: 'msn.button.edit',
    //                     key: 'edit'
    //                 }
    //             ],
    //             component: resolve => require(['@/modules/msn/pages/helpCenter/qaCenter'], resolve)
    //         },
    //         {
    //             icon: 'monthlyReport',
    //             hideInMenu: true, // 是否在菜单中隐藏
    //             key: 'monthlyReport',
    //             title: 'msn.menuData.monthlyReportTitle',
    //             pTitle: 'msn.menuData.monthlyReportTitle',
    //             component: resolve => require(['@/modules/msn/pages/am/yueBao'], resolve)
    //         },
    //         {
    //             icon: 'notifications',
    //             key: 'notification',
    //             title: 'msn.menuData.notification',
    //             pTitle: 'msn.menuData.notification',
    //             component: resolve => require(['@/modules/msn/pages/am/notice'], resolve)
    //         }
        ]
    },
    {
        icon: 'create',
        key: 'campaign',
        title: 'msn.menuData.campaignTitle',
        pTitle: 'msn.menuData.campaignTitle',
        component: resolve => require(['@/modules/msn/pages/campaignMgr/create'], resolve),
        actions: [
            {
                pTitle: 'msn.create.osv',
                key: 'osv'
            },
            {
                pTitle: 'msn.create.moreBtn',
                key: 'moreBtn'
            },
            {
                pTitle: 'msn.create.userPackage',
                key: 'userPackage'
            },
            {
                pTitle: 'msn.create.impURL',
                key: 'impURL'
            },
            {
                pTitle: 'domainwhitelist',
                key: 'domainwhitelist'
            },
            {
                pTitle: 'msn.supplement.locationJapan',
                key: 'locationJapan'
            },
            {
                // 预算自动管理
                pTitle: 'msn.supplement.monthlyCapStatus',
                key: 'monthlyCapStatus'
            },
            {
                // 广告投放目的
                pTitle: 'msn.supplement.objective',
                key: 'objective'
            },
            {
                // 受众
                pTitle: 'msn.supplement.audience',
                key: 'audience'
            },
            {
                pTitle: 'msn.supplement.locationIP',
                key: 'locationIP'
            },
            {
                pTitle: 'msn.supplement.locationTW',
                key: 'locationTW'
            },
            {
                pTitle: 'msn.create.postback',
                key: 'postback'
            },
            {
                pTitle: 'msn.supplement.regionEurope',
                key: 'regionEurope'
            },
            {
                pTitle: 'msn.supplement.bulkUpload',
                key: 'bulkUpload'
            },
            {
                pTitle: 'msn.create.smartBidText',
                key: 'zeroThreshold'
            },
            {
                pTitle: 'msn.create.displaySize',
                key: 'displaySize'
            },
            {
                pTitle: 'msn.create.smartBidmaxcv',
                key: 'smartBidMaxCV'
            }
        ]
    },
    {
        icon: 'create',
        key: 'rtbCopy',
        title: 'msn.menuData.rtbCopyTitle',
        pTitle: 'msn.menuData.rtbCopyTitle',
        component: resolve => require(['@/modules/msn/pages/campaignMgr/copy'], resolve)
    },
    // {
    //     icon: 'aiCreativeGo',
    //     cornerIcon: 'newIcon-rightTop',
    //     key: 'popInCreativeMaker',
    //     title: 'msn.menuData.popInCreativeMakerTitle',
    //     pTitle: 'msn.menuData.popInCreativeMakerTitle',
    //     component: resolve => require(['@/modules/msn/pages/aiCreativeGo'], resolve),
    //     actions: []
    // },
    {
        icon: 'pixel',
        key: 'pixel',
        title: 'msn.menuData.pixel',
        pTitle: 'msn.menuData.pixel',
        children: [
            // {
            //     icon: 'conversionList',
            //     key: 'conversionList',
            //     title: 'msn.menuData.conversionListTitle',
            //     pTitle: 'msn.menuData.conversionListTitle',
            //     actions: [
            //         {
            //             pTitle: 'msn.dashboard.status',
            //             key: 'status'
            //         },
            //         {
            //             pTitle: 'msn.pixel.create',
            //             key: 'create'
            //         },
            //         {
            //             pTitle: 'msn.button.edit',
            //             key: 'edit'
            //         }
            //     ],
            //     component: resolve => require(['@/modules/msn/pages/pixel/pixel'], resolve)
            // },
            {
                icon: 'conversionList',
                key: 'conversionCreate',
                pTitle: 'msn.menuData.conversionCreateTitle',
                component: resolve => require(['@/modules/msn/pages/pixel/createConversion'], resolve)
            },
            {
                icon: 'conversionList',
                key: 'readCreate',
                pTitle: 'msn.supplement.readlist',
                component: resolve => require(['@/modules/msn/pages/pixel/createRead'], resolve)
            },
            {
                icon: 'conversions',
                key: 'conversions',
                title: 'msn.menuData.conversions',
                pTitle: 'msn.menuData.conversionsTitle',
                component: resolve => require(['@/modules/msn/pages/pixel/dConversion/index'], resolve)
            },
            {
                icon: 'readList',
                key: 'readList',
                title: 'msn.menuData.readListTitle',
                pTitle: 'msn.menuData.readListTitle',
                component: resolve => require(['@/modules/msn/pages/pixel/read'], resolve)
            },
            {
                icon: 'readList',
                key: 'discoveryReadCreate',
                pTitle: 'msn.menuData.discoveryReadCreateTitle',
                component: resolve => require(['@/modules/msn/pages/pixel/discoveryReadCreate'], resolve)
            }
        ]
    },
    {
        icon: 'author',
        key: 'agencyManagement',
        bageKey: 'agentCount',
        title: 'msn.menuData.agencyManagementTitle',
        pTitle: 'msn.menuData.agencyManagementTitle',
        children: [
            {
                icon: 'account',
                key: 'account',
                // bageKey: 'underReviewAccoutCount',
                title: 'msn.menuData.accountTitle',
                pTitle: 'msn.menuData.accountTitle',
                component: resolve => require(['@/modules/msn/pages/agentMgr/accountMgr/'], resolve),
                actions: [
                    {
                        pTitle: 'msn.permissionMgr.createAccount',
                        key: 'create'
                    }
                ]
            },
            {
                icon: 'userTitle',
                key: 'user',
                // bageKey: 'unauthUserCount',
                title: 'msn.menuData.userTitle',
                pTitle: 'msn.menuData.userTitle',
                component: resolve => require(['@/modules/msn/pages/agentMgr/userMgr/'], resolve),
                actions: [
                    {
                        pTitle: 'msn.supplement.addUser',
                        key: 'create'
                    },
                    {
                        pTitle: 'msn.button.edit',
                        key: 'edit'
                    }
                ]
            }
        ]
    },
    // {
    //     icon: 'audit',
    //     key: 'audit',
    //     bageKey: 'adAuditNum',
    //     title: 'msn.menuData.auditTitle',
    //     pTitle: 'msn.menuData.auditTitle',
    //     actions: [
    //         {
    //             pTitle: 'msn.auditEn.startReviewing',
    //             key: 'auditBtn'
    //         },
    //         {
    //             pTitle: 'msn.auditEn.detail',
    //             key: 'detail'
    //         },
    //         {
    //             pTitle: 'msn.auditEn.industry',
    //             key: 'industry'
    //         },
    //         {
    //             pTitle: 'msn.auditEn.geoEdge',
    //             key: 'geoEdge'
    //         },
    //         {
    //             pTitle: 'msn.auditEn.setSensitive',
    //             key: 'sensitiveTag'
    //         },
    //         {
    //             pTitle: 'msn.auditEn.displayAllImg',
    //             key: 'displayAllImg'
    //         },
    //         {
    //             pTitle: 'msn.auditEn.baiduBrain',
    //             key: 'baiduBrain'
    //         }
    //         // {
    //         //     pTitle: 'msn.auditEn.searchSimilar',
    //         //     key: 'searchSimilar'
    //         // }
    //     ],
    //     component: resolve => require(['@/modules/msn/pages/audit/adAudit'], resolve)
    // },
    // {
    //     icon: 'searchSimilar',
    //     key: 'searchSimilarBtn',
    //     pTitle: 'msn.menuData.searchSimilar',
    //     component: resolve => require(['@/modules/msn/pages/audit/searchSimilar'], resolve)
    // },
    // {
    //     key: 'msgCenter',
    //     pTitle: 'msn.menuData.msgCenter',
    //     component: resolve => require(['@/modules/msn/pages/am/notice/index'], resolve)
    // },
    {
        icon: 'author',
        key: 'permission',
        bageKey: 'permisionCount',
        title: 'msn.menuData.permissionTitle',
        pTitle: 'msn.menuData.permissionTitle',
        children: [
            {
                icon: 'account',
                key: 'account',
                bageKey: 'underReviewAccoutCount',
                title: 'msn.menuData.accountTitle',
                pTitle: 'msn.menuData.accountTitle',
                actions: [
                    {
                        pTitle: 'msn.permissionMgr.createAccount',
                        key: 'create'
                    },
                    {
                        pTitle: 'msn.dashboard.status',
                        key: 'status'
                    },
                    {
                        pTitle: 'msn.permissionMgr.inviteLink',
                        key: 'bdInvite'
                    },
                    {
                        pTitle: 'msn.button.detail',
                        key: 'detail'
                    },
                    {
                        pTitle: 'msn.button.edit',
                        key: 'edit'
                    },
                    {
                        pTitle: 'msn.button.authorize',
                        key: 'authorize'
                    },
                    {
                        pTitle: 'msn.button.ignore',
                        key: 'ignore'
                    },
                    {
                        pTitle: 'msn.permissionMgr.copyLink',
                        key: 'invite'
                    }
                ],
                component: resolve => require(['@/modules/msn/pages/permission/accountMgr/'], resolve)
            },
            {
                icon: 'account',
                key: 'bdOpenAccount',
                pTitle: 'msn.menuData.bdOpenAccountTitle',
                component: resolve => require(
                    ['@/modules/msn/pages/permission/accountMgr/bdOpenAccount/bdOpenAccount'],
                    resolve
                )
            },
            {
                icon: 'userTitle',
                key: 'user',
                bageKey: 'unauthUserCount',
                title: 'msn.menuData.userTitle',
                pTitle: 'msn.menuData.userTitle',
                actions: [
                    {
                        pTitle: 'msn.dashboard.status',
                        key: 'status'
                    },
                    {
                        pTitle: 'msn.button.detail',
                        key: 'detail'
                    },
                    {
                        pTitle: 'msn.button.edit',
                        key: 'edit'
                    },
                    {
                        pTitle: 'msn.button.authorize',
                        key: 'authorize'
                    },
                    {
                        pTitle: 'msn.button.ignore',
                        key: 'ignore'
                    }
                ],
                component: resolve => require(['@/modules/msn/pages/permission/userMgr/'], resolve)
            },
            {
                icon: 'role',
                key: 'role',
                title: 'msn.menuData.roleTitle',
                pTitle: 'msn.menuData.roleTitle',
                actions: [
                    {
                        pTitle: 'msn.button.detail',
                        key: 'detail'
                    },
                    {
                        pTitle: 'msn.button.delete',
                        key: 'delete'
                    },
                    {
                        pTitle: 'msn.button.edit',
                        key: 'edit'
                    },
                    {
                        pTitle: 'msn.button.create',
                        key: 'create'
                    }
                ],
                component: resolve => require(['@/modules/msn/pages/permission/roleMgr/role'], resolve)
            }
        ]
    },
    // {
    //     icon: 'recharge',
    //     key: 'recharge',
    //     title: 'msn.menuData.rechargeTitle',
    //     pTitle: 'msn.menuData.rechargeTitle',
    //     actions: [
    //         {
    //             pTitle: 'msn.paymentList.recharge',
    //             key: 'payAction'
    //         },
    //         {
    //             pTitle: 'msn.menuData.stripeAction',
    //             key: 'stripe'
    //         },
    //         {
    //             pTitle: 'msn.paymentList.autoBilling',
    //             key: 'autoBilling'
    //         }
    //     ],
    //     component: resolve => require(['@/modules/msn/pages/recharge/recharge'], resolve)
    // },
    // {
    //     icon: 'monthBill',
    //     key: 'monthBill',
    //     title: 'msn.monthBill.monthBillTitle',
    //     pTitle: 'msn.monthBill.monthBillTitle',
    //     component: resolve => require(['@/modules/msn/pages/monthBill'], resolve)
    // },

    // {
    //     icon: 'payAction',
    //     key: 'payAction',
    //     pTitle: 'msn.menuData.payActionTitle',
    //     component: resolve => require(['@/modules/msn/pages/recharge/payAction'], resolve)
    // },
    // {
    //     icon: 'stripeAction',
    //     key: 'stripeAction',
    //     pTitle: 'msn.menuData.stripeAction',
    //     component: resolve => require(['@/modules/msn/pages/recharge/stripeAction'], resolve)
    // },
    // // 新增多币种切换权限配置
    {
        icon: 'currencyType',
        pTitle: 'msn.dashboard.currencyType',
        key: 'currencyType',
        component: resolve => require(['@/modules/msn/App.vue'], resolve)
    }
    // {
    //     icon: 'financial',
    //     key: 'financial',
    //     title: 'msn.menuData.financialTitle',
    //     pTitle: 'msn.menuData.financialTitle',
    //     bageKey: 'creditLineAccountAlarm',
    //     children: [
    //         {
    //             icon: 'creditLine',
    //             key: 'creditLine',
    //             bageKey: 'creditLineAccountAlarm',
    //             title: 'msn.menuData.creditLineTitle',
    //             pTitle: 'msn.menuData.creditLineTitle',
    //             actions: [
    //                 {
    //                     pTitle: 'msn.dashboard.status',
    //                     key: 'status'
    //                 },
    //                 {
    //                     pTitle: 'msn.creditLine.creditLine',
    //                     key: 'setting'
    //                 },
    //                 {
    //                     pTitle: 'msn.creditLine.charge',
    //                     key: 'charge'
    //                 },
    //                 {
    //                     pTitle: 'msn.creditLine.couponTitle',
    //                     key: 'coupon'
    //                 },
    //                 {
    //                     pTitle: 'msn.creditLine.profitRate',
    //                     key: 'profitRate'
    //                 }
    //             ],
    //             component: resolve => require(['@/modules/msn/pages/financialMgr/creditLine/'], resolve)
    //         },
    //         {
    //             icon: 'receivables',
    //             key: 'receivables',
    //             title: 'msn.menuData.receivables',
    //             pTitle: 'msn.menuData.receivables',
    //             actions: [

    //             ],
    //             component: resolve => require(['@/modules/msn/pages/financialMgr/receivables/'], resolve)
    //         },
    //         {
    //             icon: 'receivables',
    //             key: 'monthlyBill',
    //             title: 'msn.menuData.monthlyBill',
    //             pTitle: 'msn.menuData.monthlyBill',
    //             actions: [
    //                 {
    //                     pTitle: 'msn.menuData.monthlyBillSave',
    //                     key: 'monthlyBillSave'
    //                 },
    //                 {
    //                     pTitle: 'msn.menuData.monthlyBillRelease',
    //                     key: 'monthlyBillRelease'
    //                 }
    //             ],
    //             component: resolve => require(['@/modules/msn/pages/financialMgr/monthlyBill/index.vue'], resolve)
    //         }
    //     ]
    // },
    // {
    //     key: 'apply',
    //     component: resolve => require(['@/modules/msn/pages/system/noPermission'], resolve)
    // },
    // {
    //     icon: 'help',
    //     key: 'helpCenter',
    //     title: 'msn.menuData.helpCenterTitle',
    //     pTitle: 'msn.menuData.helpCenterTitle',
    //     children: [
    //         {
    //             icon: 'start',
    //             key: 'gettingStart',
    //             title: 'msn.menuData.gettingStartTitle',
    //             pTitle: 'msn.menuData.gettingStartTitle',
    //             actions: [
    //                 {
    //                     pTitle: 'msn.button.edit',
    //                     key: 'edit'
    //                 }
    //             ],
    //             component: resolve => require(['@/modules/msn/pages/helpCenter/qaCenter'], resolve)
    //         },
    //         {
    //             icon: 'createAndManageCampaign',
    //             key: 'createAndManageCampaign',
    //             title: 'msn.menuData.createAndManageCampaignTitle',
    //             pTitle: 'msn.menuData.createAndManageCampaignTitle',
    //             actions: [
    //                 {
    //                     pTitle: 'msn.button.edit',
    //                     key: 'edit'
    //                 }
    //             ],
    //             component: resolve => require(['@/modules/msn/pages/helpCenter/qaCenter'], resolve)
    //         },
    //         {
    //             icon: 'Report & Optimizing ',
    //             key: 'reportAndOptimizing',
    //             title: 'msn.menuData.reportAndOptimizingTitle',
    //             pTitle: 'msn.menuData.reportAndOptimizingTitle',
    //             actions: [
    //                 {
    //                     pTitle: 'msn.button.edit',
    //                     key: 'edit'
    //                 }
    //             ],
    //             component: resolve => require(['@/modules/msn/pages/helpCenter/qaCenter'], resolve)
    //         },
    //         {
    //             icon: 'practice',
    //             key: 'priceAndBill',
    //             title: 'msn.menuData.priceAndBillTitle',
    //             pTitle: 'msn.menuData.priceAndBillTitle',
    //             actions: [
    //                 {
    //                     pTitle: 'msn.button.edit',
    //                     key: 'edit'
    //                 }
    //             ],
    //             component: resolve => require(['@/modules/msn/pages/helpCenter/qaCenter'], resolve)
    //         },
    //         {
    //             icon: 'adPolicy',
    //             key: 'adPolicy',
    //             title: 'msn.menuData.adPolicyTitle',
    //             pTitle: 'msn.menuData.adPolicyTitle',
    //             actions: [
    //                 {
    //                     pTitle: 'msn.button.edit',
    //                     key: 'edit'
    //                 }
    //             ],
    //             component: resolve => require(['@/modules/msn/pages/helpCenter/qaCenter'], resolve)
    //         },
    //         {
    //             icon: 'externalQaCenter',
    //             key: 'faq',
    //             title: 'msn.menuData.faqTitle',
    //             pTitle: 'msn.menuData.faqTitle',
    //             actions: [
    //                 {
    //                     pTitle: 'msn.button.edit',
    //                     key: 'edit'
    //                 }
    //             ],
    //             component: resolve => require(['@/modules/msn/pages/helpCenter/qaCenter'], resolve)
    //         }
    //     ]
    // }
    // {
    //     icon: 'visual',
    //     key: 'test',
    //     title: 'msn.menuData.visualTestTitle',
    //     pTitle: 'msn.menuData.visualTestTitle',
    //     children: [
    //         {
    //             icon: 'visualTest',
    //             key: 'visualTest',
    //             title: 'msn.menuData.visualTestTitle',
    //             pTitle: 'msn.menuData.visualTestTitle',
    //             component: resolve => require(['@/modules/msn/pages/visualTest/adDemo'], resolve)
    //         },
    //         {
    //             icon: 'visualTest',
    //             key: 'visualBannerTest',
    //             title: 'msn.menuData.visualTestBannerTitle',
    //             pTitle: 'msn.menuData.visualTestBannerTitle',
    //             component: resolve => require(['@/modules/msn/pages/visualTest/adDemoBanner'], resolve)
    //         },
    //         {
    //             icon: 'menuData',
    //             key: 'checkStatus',
    //             title: 'msn.menuData.checkStatusTitle',
    //             pTitle: 'msn.menuData.checkStatusTitle',
    //             component: resolve => require(['@/modules/msn/pages/visualTest/checkStatus'], resolve),
    //             actions: [
    //                 {
    //                     pTitle: 'msn.button.innerStatus',
    //                     key: 'innerStatus'
    //                 }
    //             ]
    //         },
    //         {
    //             icon: 'menuData',
    //             key: 'pageBid',
    //             title: 'msn.menuData.pageBidTitle',
    //             pTitle: 'msn.menuData.pageBidTitle',
    //             actions: [
    //                 {
    //                     pTitle: 'msn.button.edit',
    //                     key: 'edit'
    //                 },
    //                 {
    //                     pTitle: 'msn.button.pageAudit',
    //                     key: 'audit'
    //                 },
    //                 {
    //                     pTitle: 'msn.button.history',
    //                     key: 'history'
    //                 }
    //             ],
    //             component: resolve => require(['@/modules/msn/pages/visualTest/price.vue'], resolve)
    //         },
    //         {
    //             icon: 'menuData',
    //             key: 'generateLocalPageWrapper',
    //             title: 'msn.menuData.generateLocalPageWrapper',
    //             pTitle: 'msn.menuData.generateLocalPageWrapper',
    //             actions: [
    //                 {
    //                     pTitle: 'msn.visualTest.adminEmailCode',
    //                     key: 'adminEmailCode'
    //                 }
    //             ],
    //             component: resolve => require(['@/modules/msn/pages/visualTest/generateLocalPageWrapper.vue'], resolve)
    //         }
    //     ]
    // }
];

/**
 * 获取真正的路径
 * @param  {string} path 路径
 * @param  {boolean} hasModule 是否包含模块
 * @return {string}
 */
function getModulePath(path, hasModule = true) {
    return path
        ? (hasModule ? [env['VUE_APP_BASE_URI'], module, path]
            : [env['VUE_APP_BASE_URI'], path]).join('/')
        : '/';
}

/**
 * 通过前面的config配置，获取menu和路径配置
 *
 * @param  {Object} config [description]
 * @return {Object}        [description]
 */
function getConfig(config) {
    let routerConfig = [];
    let path = {};
    // 获取discovery权限目录
    let excludeRoutes = getDiscoveryExcludeRoutes();
    if(config && excludeRoutes.length) {
        config = config.filter((route) => excludeRoutes.findIndex(item => item === route.key) <= -1);
    }
    // 需要将数据深克隆，否则后续的更改会修改数据
    const rawConfig = JSON.parse(JSON.stringify(config));
    let author = rawConfig.reduce((ret, item) => {
        if (item['key']) {
            let curPath = getModulePath(item['key']);
            if (item['pTitle']) {
                if (item['children']) {
                    item['children'] = item['children'].reduce((ret, child) => {
                        if (child['key']) {
                            let keys = [item['key'], child['key']];
                            let curPath = getModulePath(keys.join('/'));
                            if (child['pTitle']) {
                                child['path'] = curPath;
                                if (child['actions']) {
                                    child['children'] = child['actions'].map(action => {
                                        return {
                                            path: `${curPath}:${action.key}`,
                                            ...action
                                        };
                                    });
                                }
                                ret.push(child);
                            }
                        }
                        return ret;
                    }, []);
                }
                item['path'] = curPath;
                if (item['actions']) {
                    item['children'] = item['actions'].map(action => {
                        return {
                            path: `${curPath}:${action.key}`,
                            ...action
                        };
                    });
                }
                ret.push(item);
            }
        }
        return ret;
    }, []);
    let menu = config.reduce((ret, item) => {
        if (item['key']) {
            let curPath = getModulePath(item['key']);
            if (item['title']) {
                // item['title'] = this.$i18n.t(item['title']);
                if (item['children']) {
                    item['children'] = item['children'].reduce((ret, child) => {
                        if (child['key']) {
                            let keys = [item['key'], child['key']];
                            let curPath = getModulePath(keys.join('/'));
                            if (child['title']) {
                                // child['title'] = this.$i18n.t(child['title']);
                                child['path'] = curPath;
                                ret.push(child);
                            }
                            path[keys.join('_')] = curPath;
                            if (child['component']) {
                                // let componentPath = ['@/modules', module, 'pages', ...keys].join('/');
                                // console.log(componentPath);
                                routerConfig.push({
                                    path: path[keys.join('_')],
                                    component: child['component']
                                });
                            }
                        }
                        return ret;
                    }, []);
                }
                item['path'] = curPath;
                ret.push(item);
            }
            path[item['key']] = curPath;
            if (item['component']) {
                routerConfig.push({
                    path: path[item['key']],
                    component: item['component']
                });
            }
        }
        return ret;
    }, []);
    return {
        menu,
        path,
        author,
        routes: routerConfig
    };
}

export default getConfig(config);
