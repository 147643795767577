var render = function render(){var _vm=this,_c=_vm._self._c;return _c('my-custom-select',{directives:[{name:"loadmore",rawName:"v-loadmore",value:(_vm.handleScroll),expression:"handleScroll"}],ref:"elSelect",staticClass:"custom-select",class:_vm.className,attrs:{"clearable":_vm.clearable,"collapse-tags":_vm.collapseTags,"default-first-option":_vm.defaultFirstOption,"disabled":_vm.disabled,"filter-method":_vm.filterMethod,"filterable":_vm.filterable,"loading-text":_vm.loadingText,"loading":_vm.remoteLoading,"multiple":_vm.multiple,"placeholder":_vm.placeholder,"popper-class":_vm.popperClass,"remote-method":_vm.handleRemoteMethod,"remote":_vm.remote,"reserve-keyword":_vm.reserveKeyword,"value-key":_vm.valueKey},on:{"change":(value) => {
        _vm.$emit('change', value)
    },"clear":_vm.handleClear,"clearquery":_vm.clearQuery,"focus":() => {
        _vm.$emit('focus', _vm.value)
    },"get-query":_vm.getQuery,"remove-tag":_vm.handleRemoveTag,"visible-change":_vm.handleVisibleChange,"get-selected":_vm.getSelectedData},nativeOn:{"keydown":function($event){return (() => {
        _vm.$emit('keydown', _vm.value)
    }).apply(null, arguments)}},model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}},_vm._l((_vm.options),function(option,index){return _c('el-option',{key:_vm.optionLable + _vm.optionValue + index,attrs:{"disabled":option.disabled,"label":_vm.useTranslate ? _vm.$t(option[_vm.optionLable]) : option[_vm.optionLable],"value":_vm.labelAndValue ? option : option[_vm.optionValue]}},[(_vm.showItemImage)?_c('img',{staticClass:"preview-img left",attrs:{"src":option.img || _vm.bgImg}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"left"},[_vm._v(_vm._s(_vm.useTranslate ? _vm.$t(option.label) : option.label))]),_vm._v(" "),(_vm.showOptionAppend)?_c('span',{staticClass:"right"},[_vm._v(_vm._s(option[_vm.optionAppendKey]))]):_vm._e()])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }