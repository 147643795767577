// 根据域名动态添加gtag
(function () {
    const gidMedia = 'G-ZX5XQZNMRL'; // 媒体正式 新版GA
    const gidRegister = 'UA-164476616-4'; // 平台注册 GA
    const gidF = 'UA-164476616-2'; // 正式
    const gidT = 'UA-164476616-3'; // 测试
    const hostname = location.hostname;
    const isDev = !(hostname.indexOf('mediago.io') > -1 || hostname.indexOf('popin.cc') > -1);
    const gid = isDev ? gidT : gidF;
    let a = document.createElement('script');
    let m = document.getElementsByTagName('script')[0];
    a.async = 1;
    a.src = 'https://www.googletagmanager.com/gtag/js?id=' + gid;
    m.parentNode.insertBefore(a, m);
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        window.dataLayer.push(arguments);
    }
    window.gtag = gtag;
    gtag('js', new Date());
    gtag('config', gid, {
        custom_map: {
            // once dimension 每次会话上报一次
            dimension1: 'email',
            dimension2: 'roles',
            dimension5: 'brand',
            dimension9: 'accounts_count',
            dimension3: 'wait_seconds',
            dimension4: 'errors',
            dimension6: 'timezone',
            dimension7: 'currency',
            dimension8: 'lang',
            dimension10: 'cols'
        }
    });
    if (isDev) {
        // gtag('config', gid, {
        //     groups: 'normal',
        //     custom_map: {dimension1: 'blog_name'}
        // });
        // 媒体 测试 GA
        gtag('config', gidMedia, {
            debug_mode: true,
            traffic_type: 'baidu',
            groups: 'media'
        });
    } else {
        // 平台 GA
        // gtag('config', gid, { groups: 'normal' });
        // 媒体 GA
        gtag('config', gidMedia, {
            groups: 'media'
        });
        // 注册 GA
        gtag('config', gidRegister, {
            groups: 'register',
            custom_map: {dimension1: 'blog_name'}
        });
    }
})();
