/**
 * @file 编辑项
 * @author  fangsimin
 * @date    2019-01-18 16:03:38
 * @last Modified by    fangsimin
 * @last Modified time  2019-01-18 19:34:12
 */

<template>
    <div>
        <template v-if="type === constants['editTypes']['input']">
            test
            <!-- <el-input :value="value" @blur="this.value = $event"></el-input> -->
        </template>
        <template v-else>
            {{ value }}
        </template>
    </div>
</template>

<script>
import constants from './constants';

export default {
    props: {
        type: constants['editTypes']['text'], // 类型：默认为不可编辑的文本
        value: {
            default: ''
        }
    },
    data() {
        return {
            constants
        };
    },
    watch: {
        value(val) {
            // 监听value变化外传，使得可以使用v-model双向绑定
            this.$emit('input', val);
        }
    }
};
</script>
