var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"page-no-permission"},[_c('header',{staticClass:"login-header"},[_c('div',{staticClass:"login-logo",class:{'login-logo-discovery': _vm.isDiscovery}})]),_vm._v(" "),_c('el-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"box-card card-container",attrs:{"element-loading-spinner":"el-icon-loading"}},[_c('header',{staticClass:"card-header"},[_vm._v("\n            "+_vm._s(_vm.$t("msn.permission.title"))+"\n            "),_c('span',{staticStyle:{"font-size":"12px","float":"right"}},[_vm._v("\n                "+_vm._s(_vm.$t("msn.permission.tologinText", {platform: _vm.platform}))+"\n                "),_c('router-link',{staticClass:"link",style:(_vm.lang === 'en' ? {'margin-left': '5px'} : {}),attrs:{"to":{path: _vm.loginPath}}},[_vm._v("\n                    "+_vm._s(_vm.$t("msn.login.loginText"))+"\n                ")])],1)]),_vm._v(" "),_c('section',{staticClass:"card-body"},[_c('el-form',{ref:"form",staticClass:"form-container",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('el-form-item',{class:[
                        'msn-form-item',
                        _vm.focusStyle.email,
                        _vm.blurStyle.email,
                    ],attrs:{"prop":"email"}},[_c('div',{staticClass:"msn-label required",domProps:{"textContent":_vm._s(_vm.$t('msn.permission.formEmail'))}}),_vm._v(" "),_c('el-input',{on:{"focus":function($event){return _vm.handleInputFocus('email')},"change":function($event){return _vm.handleGa('in')},"blur":function($event){return _vm.handleInputBlur('email')}},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_vm._v(" "),_c('count-down-btn',{ref:"countDown",staticClass:"count-down",on:{"click":_vm.handleSendVCode}},[_vm._v("\n                        "+_vm._s(_vm.$t("msn.permission.vCode"))+"\n                    ")])],1),_vm._v(" "),_c('el-form-item',{class:[
                        'msn-form-item',
                        _vm.focusStyle.vCode,
                        _vm.blurStyle.vCode,
                    ],attrs:{"prop":"vCode"}},[_c('div',{staticClass:"msn-label required"},[_vm._v("\n                        "+_vm._s(_vm.$t("msn.permission.inputVCode"))+"\n                    ")]),_vm._v(" "),_c('el-input',{on:{"focus":function($event){return _vm.handleInputFocus('vCode')},"blur":function($event){return _vm.handleInputBlur('vCode')},"change":function($event){return _vm.handleGa('in')}},model:{value:(_vm.form.vCode),callback:function ($$v) {_vm.$set(_vm.form, "vCode", $$v)},expression:"form.vCode"}})],1),_vm._v(" "),_c('el-form-item',{class:[
                        'msn-form-item',
                        _vm.focusStyle.country,
                        _vm.blurStyle.country,
                    ],attrs:{"prop":"country"}},[_c('div',{staticClass:"msn-label required"},[_vm._v("\n                        "+_vm._s(_vm.$t("msn.permission.country"))+"\n                    ")]),_vm._v(" "),_c('el-select',{staticClass:"p-select",attrs:{"filterable":"","popper-class":"p-select-popper","placeholder":""},on:{"focus":function($event){return _vm.handleInputFocus('country')},"blur":function($event){return _vm.handleInputBlur('country')},"change":function($event){return _vm.handleGa('in')}},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}},_vm._l((_vm.countryList),function(item){return _c('el-option',{key:item.value,attrs:{"label":_vm.$t(item.label),"value":item.value}})}),1)],1),_vm._v(" "),_c('el-form-item',{class:[
                        'msn-form-item',
                        _vm.focusStyle.timeZone,
                        _vm.blurStyle.timeZone,
                    ],attrs:{"prop":"timeZone"}},[_c('div',{staticClass:"msn-label required"},[_vm._v("\n                        "+_vm._s(_vm.$t("msn.permission.timeZone"))+"\n                    ")]),_vm._v(" "),_c('el-select',{staticClass:"p-select",attrs:{"popper-class":"p-select-popper","placeholder":""},on:{"focus":function($event){return _vm.handleInputFocus('timeZone')},"blur":function($event){return _vm.handleInputBlur('timeZone')},"change":function($event){return _vm.handleGa('in')}},model:{value:(_vm.form.timeZone),callback:function ($$v) {_vm.$set(_vm.form, "timeZone", $$v)},expression:"form.timeZone"}},_vm._l((_vm.timeZoneList),function(item){return _c('el-option',{key:item.value,attrs:{"label":_vm.$t(item.label),"value":item.value}})}),1),_vm._v(" "),_c('el-popover',{ref:"popover",attrs:{"placement":"right","width":"200","trigger":"hover"}},[_c('div',{staticClass:"msn-pw-tip"},[_c('div',{staticClass:"msn-pw-tip--item"},[_vm._v("\n                                "+_vm._s(_vm.$t('msn.permission.timeZoneTip'))+"\n                            ")])])]),_vm._v(" "),_c('i',{directives:[{name:"popover",rawName:"v-popover:popover",arg:"popover"}],staticClass:"msn-tip msn-fa msn-fa-notice"})],1),_vm._v(" "),_c('el-form-item',{class:[
                        'msn-form-item',
                        _vm.focusStyle.currency,
                        _vm.blurStyle.currency,
                    ],attrs:{"prop":"settlementCurrency"}},[_c('div',{staticClass:"msn-label required"},[_vm._v("\n                        "+_vm._s(_vm.$t("msn.permission.currency"))+"\n                    ")]),_vm._v(" "),_c('el-select',{staticClass:"p-select",attrs:{"filterable":"","popper-class":"p-select-popper","placeholder":""},on:{"focus":function($event){return _vm.handleInputFocus('currency')},"blur":function($event){return _vm.handleInputBlur('currency')},"change":function($event){return _vm.handleGa('in')}},model:{value:(_vm.form.settlementCurrency),callback:function ($$v) {_vm.$set(_vm.form, "settlementCurrency", $$v)},expression:"form.settlementCurrency"}},_vm._l((_vm.settlementCurrencyList),function(item){return _c('el-option',{key:item.value,attrs:{"label":_vm.$t(item.label),"value":item.value}})}),1),_vm._v(" "),_c('el-popover',{ref:"popoverCurrency",attrs:{"placement":"right","width":"200","trigger":"hover"}},[_c('div',{staticClass:"msn-pw-tip"},[_c('div',{staticClass:"msn-pw-tip--item"},[_vm._v("\n                                "+_vm._s(_vm.$t('msn.permission.currencyTip'))+"\n                            ")])])]),_vm._v(" "),_c('i',{directives:[{name:"popover",rawName:"v-popover:popoverCurrency",arg:"popoverCurrency"}],staticClass:"msn-tip msn-fa msn-fa-notice"})],1),_vm._v(" "),_c('el-row',{attrs:{"gutter":20,"justify":"space-between"}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:[
                                'msn-form-item',
                                _vm.focusStyle.firstName,
                                _vm.blurStyle.firstName,
                            ],attrs:{"prop":"firstName"}},[_c('div',{staticClass:"msn-label required"},[_vm._v("\n                                "+_vm._s(_vm.$t("msn.permission.firstName"))+"\n                            ")]),_vm._v(" "),_c('el-input',{on:{"focus":function($event){return _vm.handleInputFocus('firstName')},"blur":function($event){return _vm.handleInputBlur('firstName')},"change":function($event){return _vm.handleGa('in')}},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})],1)],1),_vm._v(" "),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:[
                                'msn-form-item',
                                _vm.focusStyle.lastName,
                                _vm.blurStyle.lastName,
                            ],attrs:{"prop":"lastName"}},[_c('div',{staticClass:"msn-label required"},[_vm._v("\n                                "+_vm._s(_vm.$t("msn.permission.lastName"))+"\n                            ")]),_vm._v(" "),_c('el-input',{on:{"focus":function($event){return _vm.handleInputFocus('lastName')},"blur":function($event){return _vm.handleInputBlur('lastName')},"change":function($event){return _vm.handleGa('in')}},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})],1)],1)],1),_vm._v(" "),_c('el-row',{attrs:{"gutter":20,"justify":"space-between"}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:[
                                'msn-form-item',
                                _vm.focusStyle['companyWebsite'],
                                _vm.blurStyle.companyWebsite,
                            ],attrs:{"prop":"companyWebsite"}},[_c('div',{staticClass:"msn-label required"},[_vm._v("\n                                "+_vm._s(_vm.$t("msn.permission.companySite"))+"\n                            ")]),_vm._v(" "),_c('el-input',{on:{"focus":function($event){return _vm.handleInputFocus('companyWebsite')},"blur":function($event){return _vm.handleInputBlur('companyWebsite')},"change":function($event){return _vm.handleGa('in')}},model:{value:(_vm.form.companyWebsite),callback:function ($$v) {_vm.$set(_vm.form, "companyWebsite", $$v)},expression:"form.companyWebsite"}})],1)],1),_vm._v(" "),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:[
                                'msn-form-item',
                                _vm.focusStyle['jobTitle'],
                                _vm.blurStyle.jobTitle,
                            ],attrs:{"prop":"jobTitle"}},[_c('div',{staticClass:"msn-label required"},[_vm._v("\n                                "+_vm._s(_vm.$t("msn.permission.jobTitle"))+"\n                            ")]),_vm._v(" "),_c('el-input',{on:{"focus":function($event){return _vm.handleInputFocus('jobTitle')},"blur":function($event){return _vm.handleInputBlur('jobTitle')},"change":function($event){return _vm.handleGa('in')}},model:{value:(_vm.form.jobTitle),callback:function ($$v) {_vm.$set(_vm.form, "jobTitle", $$v)},expression:"form.jobTitle"}})],1)],1)],1),_vm._v(" "),_c('input',{staticClass:"is-hidden",attrs:{"type":"text"}}),_vm._v(" "),_c('input',{staticClass:"is-hidden",attrs:{"type":"password"}}),_vm._v(" "),_c('el-form-item',{class:[
                        'msn-form-item',
                        _vm.focusStyle.password,
                        _vm.blurStyle.password,
                    ],attrs:{"prop":"password"}},[_c('div',{staticClass:"msn-label required"},[_vm._v("\n                        "+_vm._s(_vm.$t("msn.permission.password"))+"\n                    ")]),_vm._v(" "),_c('el-input',{directives:[{name:"popover",rawName:"v-popover:popover1",arg:"popover1"}],staticClass:"msn-password",attrs:{"type":"password","show-password":""},on:{"focus":function($event){return _vm.handleInputFocus('password')},"blur":function($event){return _vm.handleInputBlur('password')},"change":function($event){return _vm.handleGa('in')}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_vm._v(" "),_c('el-popover',{ref:"popover1",attrs:{"placement":"right","width":"200","trigger":"focus"}},[_c('password-tip',{attrs:{"value":_vm.form.password}})],1)],1)],1)],1),_vm._v(" "),_c('footer',{staticClass:"page-footer"},[_c('protocol',{attrs:{"hide-privacy":true},model:{value:(_vm.protocolForm),callback:function ($$v) {_vm.protocolForm=$$v},expression:"protocolForm"}}),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleApply}},[_vm._v("\n                "+_vm._s(_vm.$t("msn.permission.apply"))+"\n            ")])],1)]),_vm._v(" "),_c('div',{staticClass:"bg-text"},[_c('h1',[_vm._v("\n            Tap into a global, premium"),_c('br'),_vm._v("media ecosystem with "+_vm._s(_vm.platform)+".\n        ")]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('protocol-preview',{ref:"pp",attrs:{"visible":_vm.ppShow},on:{"close":function($event){_vm.ppShow = false},"submit":_vm.sendApply}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"h2-wrap"},[_c('li',{staticClass:"h2-item"},[_c('h2',{staticClass:"normal-text"},[_vm._v("\n                    Premium native inventory with top sites\n                ")])]),_vm._v(" "),_c('li',{staticClass:"h2-item"},[_c('h3',{staticClass:"normal-text"},[_vm._v("\n                    Precise audience targeting, transparency and trust\n                ")])])])
}]

export { render, staticRenderFns }