
import Vue from 'vue';

/** ========= weirwood config begins ========= */

import * as Weirwood from '@baidu/weirwood-sdk';
import WeirwoodOptions from '../../weirwood.json';
const options = {
    common: {
        // 只支持如下字符：英文字母(大小写)，数字(0-9)，下划线(_)，中划线(-)，点(.)，@符号
        buildid: WeirwoodOptions.buildid,
        token: WeirwoodOptions.token,
        ignoreUrls: [
            // 本地开发屏蔽错误发送
            'localhost',
            '127.0.0.1'
        ]
    },
    error: {
        collectWindowErrors: true,
        collectUnhandledRejections: true,
        // 静态资源加载异常
        collectResourceLoadErrors: true
    },
    perf: {
        // 性能数据PV日志会比较大，可以输入 sampleRate 进行采样，控制在 50 W左右
        sampleRate: 1,
        spa: true,
        history: true,
        disable: true // 关闭性能监控
    }
};

const weirwood = Weirwood.init(options);

// 核心：Hook Vue 的错误方法，调用 'weirwood' 的 'captureException' 方法
Vue.config.errorHandler = function (err) {
    weirwood.error.captureException(err);
};


// weirwood 主动上报
export function captrueApiException(response) {
    if (weirwood && weirwood.error && weirwood.error.captureAPIException) {
        weirwood.error.captureAPIException({
            url: response.config.url, // 必填，错误接口路径
            method: response.config.method, // 必填，请求 method
            params: response.config.params, // 可选，请求参数，如果是 JSON 对象，不需要 JSON 序列化，直接赋值给 params 即可
            status: response.data.code, // 必填，响应状态，可以自定义
            response: response // 可选，响应结果，如果是 JSON 对象，不需要 JSON 序列化，直接赋值给 response 即可
        });
    }
};
/** ========= weirwood config ends ========= */