<template>
    <el-select
        ref="elSelect"
        v-model="select"
        v-el-select-loadmore="loadmore"
        :class="className"
        :filterable="filterable"
        :disabled="disabled"
        :placeholder="currentPlaceHolder"
        :collapse-tags="collapseTags"
        :popper-class="popperClass"
        :multiple="multiple"
        :multiple-limit="multipleLimit"
        :filter-method="filterMethod"
        :clearable="clearable"
        :allow-create="allowCreate"
        :reserve-keyword="reserveKeyword"
        @visible-change="handleVisibleChange"
        @clear="
            () => {
                $emit('clear');
            }
        "
        @change="
            (value) => {
                $emit('change', value);
            }
        "
    >
        <!-- <li v-if="showSelectAll" class="el-select-dropdown__item select-all-container">
            <span
                :class="['select-all-item', selectAll === 'all' ? 'active' : '']"
                @click="handleSelect('all')">{{$t('msn.constants.selectAll')}}</span>
            <span
                :class="['select-all-item', selectAll === 'cancel' ? 'active' : '']"
                @click="handleSelect('cancel')">{{$t('msn.constants.cancel')}}</span>
        </li> -->
        <template v-if="showPrefixImg" #prefix>
            <img class="preview-img preview-img__1" :src="options[select].img || bgImg">
        </template>
        <el-option
            v-for="(option, index) in options" :key="option.label + option.value + index"
            :label="useTranslate ? $t(option.label, option.langVar) : option.label" :value="tagOption ? $t(option.value, option.langVar) : option.value"
            :disabled="option.disabled"
        >
            <img
                v-if="showItemImage" class="preview-img left"
                :src="option.img || bgImg"
            >
            <span class="left">{{ useTranslate ? $t(option.label, option.langVar) : option.label }}</span>
            <span v-if="showOptionAppend" class="right">{{ option.append }}</span>
        </el-option>
        <div
            v-if="noMore" class="no-more"
            v-text="'end'"
        ></div>
        <div
            v-if="addItem" class="add-item"
            @click="$emit('add-item')"
        >
            <span style="font-size: 12px"><i data-v-d13c85be="" class="msn-fa msn-fa-add"></i></span>
            {{ $t("msn.button.add") }}
        </div>
        <div
            v-if="moreItem" class="add-item"
            @click="$emit('add-item')"
        >
            <span style="font-size: 12px"><i data-v-d13c85be="" class="msn-fa msn-fa-add"></i></span>
            More
        </div>
    </el-select>
</template>

<script>
import bgImg from '@/modules/msn/assets/images/icon/300x175.png';
export default {
    name: 'MsnSelect',
    props: {
        value: {
            default: ''
        },
        disabled: {
            default: false
        },
        clearable: {
            default: false
        },
        optionList: {
            default: () => []
        },
        multiple: {
            default: false
        },
        multipleLimit: {
            default: 0
        },
        placeholder: {
            default: null
        },
        filterable: {
            default: false
        },
        collapseTags: {
            default: false
        },
        reserveKeyword: {
            default: false
        },
        sort: {
            default: false
        },
        popperClass: {
            default: ''
        },
        useTranslate: {
            default: true
        },
        showOptionAppend: Boolean,
        loadmore: {
            type: Function,
            default: null
        },
        showSelectAll: Boolean,
        showPrefixImg: {
            default: false
        },
        addItem: Boolean,
        moreItem: {
            default: false,
            type: Boolean
        },
        // eslint-disable-next-line vue/require-default-prop
        showItemImage: Boolean,
        allowCreate: {
            type: Boolean,
            default: false
        },
        // 原名被占用
        filterMethodFn: {
            type: Function,
            default: null
        },
        noMore: {
            type: Boolean,
            default: false
        },
        tagOption: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            options: [],
            selectAll: false,
            bgImg
        };
    },
    computed: {
        currentPlaceHolder() {
            let flag = Array.isArray(this.select) ? this.select.length > 0 : (this.select !== '');
            return flag ? '' : this.placeholder;
        },
        select: {
            get() {
                if (this.sort) {
                    this.sortOptions(this.value);
                }
                return this.value;
            },
            set(val) {
                if (this.sort) {
                    this.sortOptions(val);
                }
                this.$emit('input', val);
            }
        },
        className() {
            let className = 'msn-select';
            if (this.disabled) {
                className = className + ' disabled';
            }
            if (this.collapseTags) {
                className = className + ' collapsed';
            }
            return className;
        }
    },
    watch: {
        optionList: {
            deep: true,
            immediate: true,
            handler(val) {
                if (Array.isArray(val)) {
                    this.options = val.concat();
                } else {
                    this.options = [];
                }
            }
        }
    },
    methods: {
        handleVisibleChange(val) {
            this.$emit('visibleChange', val);
            this.filterMethod('');
        },
        sortOptions(val) {
            this.options = this.options.sort((a, b) => {
                let selectA = val.indexOf(a.value) > -1;
                let selectB = val.indexOf(b.value) > -1;
                if (selectA && !selectB) {
                    return -1;
                }
                if (!selectA && selectB) {
                    return 1;
                }
                return a.value > b.value ? 1: -1;
            });
        },
        // 代理商过滤筛选
        filterMethod(search) {
            // 自定义过滤
            if (this.filterMethodFn) {
                const option = {
                    search
                };
                const newOptions = this.filterMethodFn(option);
                // Array
                if (Array.isArray(newOptions)) {
                    this.options = newOptions;
                    return;
                }
                // Promise 异步
                if (typeof newOptions === 'object' && newOptions.then) {
                    newOptions.then(res => {
                        Array.isArray(res) && (this.options = res);
                    });
                    return;
                };
                return;
            }
            if (this.loadmore) {
                this.loadmore(search, 'filter');
                return;
            }
            if (search) {
                this.options = this.optionList.filter(agl => {
                    // 如果需要翻译，那搜索就要改变
                    let label = '';
                    if (this.useTranslate) {
                        label = this.$t(agl.label);
                        label = label.toLowerCase();
                    } else {
                        label = agl.label.toLowerCase();
                    }
                    let append = agl.append ? agl.append.toLowerCase() : '';
                    search = search.toLowerCase();
                    return label.includes(search) || append.includes(search);
                });
            } else {
                this.options = this.optionList.map(opt => opt);
            }
        },
        // 全选
        handleSelect(val) {
            this.selectAll = val;
            if (this.selectAll === 'all') {
                this.select = this.options.map(opt => opt.value);
            } else {
                this.select = [];
            }
        }
    },
    directives: {
        'el-select-loadmore': {
            bind(el, binding) {
                if (!binding.value) {
                    return;
                }
                // 获取element-ui定义好的scroll盒子
                const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
                SELECTWRAP_DOM.addEventListener('scroll', function () {
                    /**
                    * scrollHeight 获取元素内容高度(只读)
                    * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
                    * clientHeight 读取元素的可见高度(只读)
                    * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
                    * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
                    */
                    const LIMIT = 240;
                    const condition = this.scrollHeight - (this.scrollTop + this.clientHeight) <= LIMIT;
                    if (condition) {
                        binding.value();
                    }
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.no-more{
    font-size: 13px;
    color: #53575B !important;
    font-weight: 400;
    text-align: center;
}
.add-item {
    color: #2dd1ac;
    text-align: left;
    font-weight: 700;
    font-size: 13px;
    line-height: 1em;
    margin-top: 15px;
    cursor: pointer;
    padding-left: 25px;
}
.preview-img {
    height: 100%;
    padding: 5px;
    object-fit: cover;
    margin-right: 10px;
    width: 50px;
    box-sizing: border-box;
    &__1{
        padding: 8px;
    }
}
.left {
    float: left;
}
.right {
    float: right;
    color: #8492a6;
    margin-left: 10px;
    margin-right: 20px;
}
.align-left {
    text-align: left;
}
.selected .right {
    color: #2DD1AC;
}
</style>

<style lang="scss">
.el-scrollbar__wrap{
    max-width: 800px;
}
.msn-select {
    height: 35px;
    border-radius: 17.5px;
    box-sizing: border-box;
}
.msn-select.el-select .el-input__inner {
    border: 1px solid transparent;
    height: 35px;
    border-radius: 17.5px;
    background: #EEF1F4;
    font-weight: 500;
    font-size: 13px;
    color: #53575B;
    &:hover {
        border-color: #2DD1AC;
    }
    &:focus {
        border-color: transparent;
    }
}
.el-select-dropdown {
    border: none;
}
.el-form-item.is-error .msn-time.el-date-editor .el-input__inner {
    background-color: rgba(238, 241, 244, 0.5);
    border: 1px solid #E92754;
}
.msn-disabled-option .el-select-dropdown__item.is-disabled {
    opacity: 0.2;
}
.el-select-dropdown__item {
    font-size: 13px;
    color: #53575B !important;
    font-weight: 400;
    text-align: left;
    &.hover {
        background: #EEF4F9 !important;
    }
    &.selected {
        font-weight: 400;
    }
}
.el-select .el-input.is-focus .el-input__inner {
    border-color: #2DD1AC !important;
}
.el-select.msn-select .el-select__tags {
    padding-left: 15px;
    .el-select__input {
        margin-left: 0;
    }

    .el-tag.el-tag--info {
        background: #2DD1AC !important;
        border-radius: 13px;
        font-size: 13px;
        color: #fff;
        .el-tag__close {
            color: #fff;
            background-color: #2DD1AC;
            font-size: 1.2em;
        }
    }
}
.msn-select.el-select:hover .el-input__inner {
    border-color: #2DD1AC;
}
.msn-select.el-select.disabled:hover .el-input__inner {
    border: none;
}
.msn-select.el-select.disabled {
    cursor: not-allowed;
    opacity: 0.4;
}
.msn-multi-select.el-select .el-input__inner {
    border-radius: 20px;
}
.msn-is-light {
    box-shadow: -3px 2px 2px 0 rgba(119, 151, 178, 0.16);
    border-radius: 17.5px;
    .el-input__inner {
        background-color: #fff !important;
    }
}
.el-select__caret {
    line-height: 35px !important;
}
.msn-disabled.el-select.msn-select .el-select__tags .el-tag.el-tag--info .el-tag__close {
    display: none !important;
}
// 多选的tag展示省略
.msn-multi-select {
    display: flex !important;
    align-items: center;
    .el-tag {
        max-width: 40%;
        min-width: 43px;
        .el-select__tags-text {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 80%;
        }
        .el-tag__close.el-icon-close {
            // top: -6px;
            // right: 0px;
            position: static;
        }
    }
    &.collapsed {
        .el-tag {
            border: none;
            width: auto;
            .el-select__tags-text {
                &:first-child {
                    display: inline;
                    overflow: visible;
                }
            }
            &:first-child {
                max-width: 42%;
                display: flex;
                align-items: center;
                padding-right: 2px;
                .el-select__tags-text {
                    &:first-child {
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        flex: 1;
                        // min-width: 35px;
                    }
                }
            }
        }
    }
    &.msn-disabled {
        .el-tag {
            &:first-child {
                padding-right: 8px;
            }
        }
    }
}
.msn-multi-select.disabled {
    .el-tag {
        .el-select__tags-text {
            overflow: visible;
        }
    }
}
.el-select.msn-select.msn-is-gray-tag {
    .el-select__tags .el-tag.el-tag--info {
        background: #D5DADF !important;
        color: #53575B;
        .el-tag__close.el-icon-close {
            color: #53575B;
            background-color: transparent !important;
        }
    }
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected{
    font-size: 13px;
    color: #2DD1AC !important;
}
</style>
